/* helpers for mustache-templating
 * Needs Mustache or Hogan to be loaded before it is used
 */

import {
    CANCEL, DELETE_COMMENT, FUPLOAD_DISABLE_PREPROCESSING, HOLD_DOWN_KEY, NOT_LOGGED, REPLY_LINK, SAVE_DESCRIPTION,
    SENDING_PHOTOGRAPH, SENDING_PHOTOGRAPHS, TIP, TO_SELECT_MULTIPLE_IMAGES,
} from 'mk/autogenerated/translations/mystache.6a44ab160011a49c6d2346061869417d'
import { csrfToken } from 'mk/common/csrfToken';
import { jQuery as $ } from 'mk/jQuery';
import Hogan from './hogan';

var mystacheLoad = function($node) {
    if ($node.length === 0) {
        throw new Error('template node not found');
    }
    var content = $node.html();
    // we clone the dict so that we do not modify the caller's dict

    var template = Hogan.compile(content);

    var f = function(data) {
        var d = {};
        for (var prop in data) {
            if (data.hasOwnProperty(prop)) {
                d[prop] = data[prop];
            }
        }

        d['i18n'] = function() {
            return function(text) {
                var trans = {
                    SAVE_DESCRIPTION: SAVE_DESCRIPTION,
                    CANCEL: CANCEL,
                    NOT_LOGGED: NOT_LOGGED,
                    REPLY_LINK: REPLY_LINK,
                    DELETE_COMMENT: DELETE_COMMENT,
                    SENDING_PHOTOGRAPHS___: SENDING_PHOTOGRAPHS,
                    SENDING_PHOTOGRAPH___: SENDING_PHOTOGRAPH,
                    TIP: TIP,
                    HOLD_DOWN_KEY: HOLD_DOWN_KEY,
                    TO_SELECT_MULTIPLE_IMAGES: TO_SELECT_MULTIPLE_IMAGES,
                    FUPLOAD_DISABLE_PREPROCESSING: FUPLOAD_DISABLE_PREPROCESSING,
                };

                return trans[text.trim()] || text.trim();
            };
        };

        var csrfDiv = '<div style="display:none"><input type="hidden" name="csrfmiddlewaretoken" value="' + csrfToken() + '"></div>';
        d['csrf_token'] = csrfDiv;

        return template.render(d);
    };

    return f;
};

var templateCache = {};

var mystacheGet = function(selector) {
    if (!(selector in templateCache)) {
        templateCache[selector] = mystacheLoad($(selector));
    }
    return templateCache[selector];
};

export default {get: mystacheGet};
