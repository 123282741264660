/* jshint esnext:true */ /* global window */
import {jQuery as $} from 'mk/jQuery';

/**
 * disable form field in bzform
 */
export function disableField(fieldElem) {
    // ensure we have correct element
    fieldElem = $(fieldElem).filter('.field-wrapper, .form-controls');
    if (!fieldElem.length) return;

    // setni class na cely field-wrapper, to vysedi label
    fieldElem.addClass('disabled');

    // inputy nastav readonly, tak aby submit stale odosielel hodnoty na server
    fieldElem.find('input[type=text], textarea').prop('readonly', true);
    // tlacitka deaktivuj
    fieldElem.find('button, input[type=button]').prop('disabled', true);
}

/**
 * enable form field in bzform
 */
export function enableField(fieldElem) {
    // ensure we have correct element
    fieldElem = $(fieldElem).filter('.field-wrapper, .form-controls');
    if (!fieldElem.length) return;

    // setni class na cely field-wrapper, to vysedi label
    fieldElem.removeClass('disabled');

    // zapni spat inputy
    fieldElem.find('input[type=text], textarea').prop('readonly', false);
    fieldElem.find('button, input[type=button]').prop('disabled', false);
}

/**
 * adds an error to form field in bzform
 */
export function addFieldError(fieldElem, errorMsg) {
    // ensure we have correct element
    fieldElem = $(fieldElem).filter('.field-wrapper');
    if (!fieldElem.length) return;

    fieldElem.addClass('error');
    let errors = fieldElem.find('.g-form-errors');
    if (!errors.length) {
        fieldElem.append('<div class="g-form-errors"></div>');
        errors = fieldElem.find('.g-form-errors');
    }
    errors.append(`<div class="error-msg">${errorMsg}</div>`);
}

/**
 * removes all errors from form field in bzform
 */
export function removeFieldErrors(fieldElem) {
    // ensure we have correct element
    fieldElem = $(fieldElem).filter('.field-wrapper');
    if (!fieldElem.length) return;

    fieldElem.removeClass('error');
    fieldElem.find('.g-form-errors').remove();
}

/**
 * enables code that prevents double submit of the bzform
 */
export function enableDoubleSubmitProtection(formElem) {
    formElem = $(formElem);

    formElem.submit(ev => {
        if (formElem.hasClass('submitting')) {
            ev.preventDefault();
            return false;
        } else {
            formElem.addClass('submitting');

            // start busy animation. (ios and galaxy s3 do not support it)
            let formControls = formElem.find('.form-controls');
            this.setBusyIndicator(formControls);
        }
    });
}

/**
 * remove submitting class from form and submit button
 */
export function formSubmitted(formElem) {
    formElem = $(formElem);

    formElem.removeClass('submitting');
    let formControls = formElem.find('.form-controls');
    this.cleanBusyIndicator(formControls);
}

/**
 * set busy indicator on form controls in bzform
 */
export function setBusyIndicator(formControls) {
    // ensure we have correct element
    formControls = $(formControls).filter('.form-controls');
    if (!formControls.length) return;

    let clickedBtn = formControls.find('button.clicked');
    if (!clickedBtn.length) clickedBtn = formControls.find('button.submit');

    let otherBtns = formControls.find('button').not(clickedBtn);

    clickedBtn.addClass('submitting');
    clickedBtn.append('<div class="g-spinner"/>');

    otherBtns.addClass('disabled');
    otherBtns.prop('disabled', true);
}

/**
 * remove busy indicator from form controls in bzform
 */
export function cleanBusyIndicator(formControls) {
    // ensure we have correct element
    formControls = $(formControls).filter('.form-controls');
    if (!formControls.length) return;

    let clickedBtn = formControls.find('button.clicked');
    if (!clickedBtn.length) clickedBtn = formControls.find('button.submit');

    let otherBtns = formControls.find('button').not(clickedBtn);

    clickedBtn.removeClass('submitting');
    clickedBtn.find('.g-spinner').remove();

    otherBtns.removeClass('disabled');
    otherBtns.prop('disabled', false);
}
