import { initialize } from 'mk2/apps/blogs/initialize';
import SiteMenu from 'mk2/containers/SiteMenu/SiteMenu';
import { MenuSelection } from 'mk2/schemas';
import React from 'react';
import ReactDOM from 'react-dom';

const staticSiteMenuInstances = {};
export function mountSiteMenu(element: HTMLElement, key, currentUrl: string, menuSelection: MenuSelection, hydrate: boolean) {
    if (staticSiteMenuInstances[key]) {
        // Unmount current react app
        ReactDOM.unmountComponentAtNode(staticSiteMenuInstances[key]);
        delete staticSiteMenuInstances[key];
        return;
    }

    staticSiteMenuInstances[key] = element;

    initialize(element, (props) => {
        return (
            <SiteMenu dummySticky mobileShowLogo currentUrl={currentUrl} menuSelection={menuSelection} isHeaderSticky={false} />
        );
    }, hydrate /* We are using SSR to render header */);
}
