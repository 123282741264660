import * as SentryBrowser from '@sentry/browser';
import * as bzforms from 'mk/bzforms';
import { csrfToken } from 'mk/common/csrfToken';
import * as responsive from 'mk/common/responsive';
import { jQuery as $ } from 'mk/jQuery';
import * as msgs from 'mk/msgs';
import * as xhr from 'mk/xhr/client';
import { initSentry } from 'mk2/sentry';
import '../../../static/css/main0.scss';
import * as auth from './auth';
import * as fupload from './fupload';
import * as jam from './jam';
import { initJqueryAjaxCsrf } from './jquery.ajax.csrf';
import { initJqueryShowYellowFade } from './jquery.showYellowFade';
import { initJqueryModal } from './modal';
import * as notes from './notes';
import { mountPageFooter } from './pageFooter';
import * as siteMenu from './siteMenu';

declare const window: any;
declare const ASMIRA: any;

initSentry(SentryBrowser, true);

window.getCSRFToken = csrfToken;
initJqueryAjaxCsrf();
initJqueryModal();
initJqueryShowYellowFade($);

window.xhr = xhr;

window.msgs = msgs;

window.bzforms = bzforms;

// WARNING: window.responsive.isMqMobile() is used in the Google Tag Manager
window.responsive = responsive;

window.mk__system__fupload = fupload;

window.jam = jam;
window.auth = auth;

window.Notes = notes;

window.mk__browser = {
    siteMenu,
    mountPageFooter,
};
