import { faLifeRing } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import {
    CAREER,
    CAREER_URL,
    COMMERCIAL,
    COMMERCIAL_URL,
    COOKIE_POLICY_PAGE,
    DATA_PRIVACY,
    FOLLOW_US_ON_FACEBOOK_URL,
    FOOTER_CODEX_LINK,
    FOOTER_CONTACT,
    FOOTER_DSA,
    FOOTER_HELP_LINK,
    INSTAGRAM_MK_LINK,
    RULES_BAZAAR,
    RULES_COMMON,
    STROLLER_STROLLEROPEDIA,
} from 'mk/autogenerated/translations/Footer.5700874da4a028bf48998c89be203e5e'
import assetUrl from 'mk/common/assetUrl';
import {
    aboutContactUrl,
    aboutCookiePolicyUrl,
    aboutDSAUrl,
    aboutPrivacyPolicyUrl,
    aboutRulesCodexUrl,
    aboutRulesCommonUrl,
    aboutRulesMarketUrl,
} from 'mk2/apps/about/urls';
import { faqRootUrl } from 'mk2/apps/faq/urls';
import { strollersUrl } from 'mk2/apps/strollers/urls';
import { Img } from 'mk2/components/Img';
import { Link } from 'mk2/components/Link';
import InstallPrompt from 'mk2/containers/InstallPrompt/InstallPrompt';
import React from 'react';
import styles from './Footer.mscss';

interface OwnProps {
}

export class Footer extends React.PureComponent<OwnProps> {

    public render() {
        return (
            <div
                className={cx(
                    styles.Footer,
                    styles.Footer__hideOnMobile,
                )}
            >
                <div className={styles.Footer__help}>
                    <div className={styles.Footer__links}>
                        <ul className={styles.Footer__links_row}>
                            <li>
                                <FontAwesomeIcon icon={faLifeRing} className={styles.Footer__lifering} />&nbsp;
                                <Link to={faqRootUrl}>{FOOTER_HELP_LINK}</Link>
                            </li>
                            <li>
                                <Link to={aboutRulesCodexUrl}>{FOOTER_CODEX_LINK}</Link>
                            </li>
                            <li>
                                <Link to={aboutRulesMarketUrl}>{RULES_BAZAAR}</Link>
                            </li>
                            <li>
                                <Link to={aboutRulesCommonUrl}>{RULES_COMMON}</Link>
                            </li>
                            <li>
                                <Link to={aboutContactUrl}>{FOOTER_CONTACT}</Link>
                            </li>
                            <li>
                                <Link to={aboutDSAUrl}>{FOOTER_DSA}</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={styles.Footer__footer}>
                    <div className={styles.Footer__links}>
                        <ul className={styles.Footer__links_row}>
                            <li>
                                <a href={COMMERCIAL_URL} target="_blank" rel="noopener">{COMMERCIAL}</a>
                            </li>
                            <li>
                                <a href={CAREER_URL} target="_blank" rel="noopener">{CAREER}</a>
                            </li>
                            <li>
                                <Link to={aboutCookiePolicyUrl}>{COOKIE_POLICY_PAGE}</Link>
                            </li>
                            <li>
                                <Link to={aboutPrivacyPolicyUrl}>{DATA_PRIVACY}</Link>
                            </li>
                            <li>
                                <Link to={strollersUrl}>{STROLLER_STROLLEROPEDIA}</Link>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.Footer__apps}>
                        <a className={styles.Footer__apps__app} href="https://www.youtube.com/c/Modrykonik">
                            <Img width={58} height={40} src={`${assetUrl}img/3rd-party/big-youtube.png`} />
                        </a>
                        <a className={styles.Footer__apps__app} href={FOLLOW_US_ON_FACEBOOK_URL}>
                            <Img width={40} height={40} src={`${assetUrl}img/3rd-party/big-facebook.png`} />
                        </a>
                        <a className={cx(styles.Footer__apps__app, styles.Footer__apps__bull)} href={INSTAGRAM_MK_LINK}>
                            <Img width={40} height={40} src={`${assetUrl}img/3rd-party/big-instagram.png`} />
                        </a>
                        <InstallPrompt className={styles.Footer__apps__app} />
                    </div>
                </div>
            </div>
        );
    }
}
