/* global window, console */

import toArray from "lodash-es/toArray";
import picUp from "mk/common/picUp";

import { jQuery as $ } from "mk/jQuery";
import mystache from "../old/mystache";

var Uploader = function(s) {
    // we store our "this" so that callbacks can use it when needed
    var me = this;
    var containerBox = s.containerBox ? $(s.containerBox) : $('body'); // element that encloses the uploader box with all templates

    var d = containerBox.find('#fupload-generic-data').data();

    var STATUS_NEW = 1;
    var STATUS_SENDING = 2;
    var STATUS_DONE = 3;
    var STATUS_FAILED = 4;

    var settings = {
        url: s.url,
        buttonId: s.name + '-fupload-button',
        containerId: s.name + '-fupload-container',
        multiple: s.multiple,
        buttonText: s.buttonText,
        cssClass: s.cssClass,
        extraParams: s.extraParams || {},
        errorLogURL: d.errorLogUrl,
        maxFileSize: d.maxFileSize,
        errorText: d.errorText,
        errorSizeText: d.errorSizeText,
        errorFormatText: d.errorFormatText,
        buttonBox: s.buttonBox,
        actionBox: s.actionBox,
        buttonStyle: s.buttonStyle,
        forcedRuntimes: d.forcedRuntimes,
        runtimes: d.runtimes,
    };

    if ((settings.buttonStyle !== 'block') && (settings.buttonStyle !== 'inline')) {
        throw new Error('Uploader.buttonStyle must be either "button" or "link"');
    }

    var buttonBox = settings.buttonBox;
    var actionBox = settings.actionBox;

    var fileList = [];

    var currentFileData = null;

    var isQueueSending = function() {
        var sendingFiles = fileList.filter(function(f) { return (f.status === STATUS_SENDING) || (f.status === STATUS_NEW); });
        return sendingFiles.length > 0;
    };

    var isFileSending = function() {
        var sendingFiles = fileList.filter(function(f) { return f.status === STATUS_SENDING; });
        return sendingFiles.length > 0;
    };

    var isMac = function() {
        return window.navigator.platform.indexOf('Mac') !== -1;
    };

    var calcSize = function(size) {
        var byteSize = Math.round(size / 1024 * 100) * 0.01;
        var suffix = 'KB';
        if (byteSize > 1000) {
            byteSize = Math.round(byteSize * 0.001 * 100) * 0.01;
            suffix = 'MB';
        }
        var sizeParts = byteSize.toString().split('.');
        if (sizeParts.length > 1) {
            byteSize = sizeParts[0] + '.' + sizeParts[1].substr(0, 2);
        } else {
            byteSize = sizeParts[0];
        }

        return [byteSize, suffix];
    };

    var sizeText = function(size) {
        var sizeData = calcSize(size);
        var byteSize = sizeData[0];
        var suffix = sizeData[1];
        return byteSize.toString() + ' ' + suffix;
    };

    var maxId = 0;
    var getNextId = function() {
        maxId = maxId + 1;
        return 'fupload-queue-item-id-' + maxId;
    };

    var addItems = function(fileObjs) {
        toArray(fileObjs).forEach(function(fileObj) {
            var fileId = getNextId();

            var fileData = {id: fileId, obj: fileObj};

            if (fileObj.size > settings.maxFileSize) {
                fileData.status = STATUS_FAILED;
                fileData.errorMsg = settings.errorSizeText;
            } else {
                if ((fileObj.type !== '') && (fileObj.type !== 'image/jpeg') && (fileObj.type !== 'image/png')) { // if the type cannot be determined, the value is empty-string
                    fileData.status = STATUS_FAILED;
                    fileData.errorMsg = settings.errorFormatText;
                    console.error('wrong-img-type/' + encodeURIComponent(typeof fileObj.type) + '/' + encodeURIComponent(fileObj.type));
                } else {
                    fileData.status = STATUS_NEW;
                }
            }

            fileList.push(fileData);
        });

        loop();
    };

    var markFileBegin = function() {
        if (currentFileData !== null) {
            console.log('error: currentFileData was not null');
        }

        currentFileData = [new Date()];
    };

    var markFileDone = function() {
        if (currentFileData === null) {
            console.log('error: currentFileData was not null');
        }

        var beginTime = currentFileData[0];

        currentFileData = null;

        var currentTime = new Date();
        var duration = currentTime.getTime() - beginTime.getTime();

        var msg = duration.toString();

        console.error(msg);
    };

    var markFileError = function() {
        if (currentFileData === null) {
            console.log('error: currentFileData was not null');
        }

        currentFileData = null;
    };

    var loop = function() {
        redrawQueue();

        if (isFileSending()) {
            console.log('loop: already sending, stopping.');
            return;
        }

        var newFiles = fileList.filter(function(f) { return f.status === STATUS_NEW; });

        if (newFiles.length === 0) {
            console.log('loop: nothing more to do, stopping.');
            return;
        }

        var f = newFiles[0];

        markFileBegin();

        f.status = STATUS_SENDING;
        f.progress = 0;

        $(me).trigger('uploadbegin');

        const isAdmin = window.MK
            && window.MK.requestUserPermissions
            && window.MK.requestUserPermissions.indexOf('forum.can_manage_forum') >= 0;

        picUp(f.obj, settings.url, !isAdmin,
            function(data) {
                console.log('loop: done successfully');
                f.status = STATUS_DONE;
                $(me).trigger('uploadend', [data]);
                markFileDone();
                loop();
            },
            function(errorMsg) {
                console.log('loop: failed:' + errorMsg);
                f.status = STATUS_FAILED;
                f.errorMsg = errorMsg;
                markFileError();
                loop();
            },
            function(progress) {
                f.progress = progress;
                console.log('loop: progress:' + f.progress);
                redrawQueue();
            });
    };

    var redrawQueue = function() {
        var items = fileList.map(function(fdata) {
            var fileId = fdata.id;
            var fileObj = fdata.obj;

            var FILENAME_MAX_LENGTH = 30;

            var fileName = fileObj.name;

            if (fileName.length > FILENAME_MAX_LENGTH) {
                fileName = fileName.substr(0, FILENAME_MAX_LENGTH) + '...';
            }

            var size = (typeof fileObj.size === 'number') ? sizeText(fileObj.size) : null;

            var percentage = Math.floor(100 * (fdata.progress || 0));

            return {
                itemID: fileId,
                fileName: fileName,
                size: size,
                assetUrl: window.ASSET_URL,
                isNew: fdata.status === STATUS_NEW,
                isSending: fdata.status === STATUS_SENDING,
                isDone: fdata.status === STATUS_DONE,
                isFailed: fdata.status === STATUS_FAILED,
                errorText: fdata.errorMsg,
                progress: percentage,
            };
        });

        var html = mystache.get('#fupload-new-queue-template')({
            multiple: settings.multiple,
            isSending: isQueueSending(),
            items: items,
        });
        $(actionBox).html(html);
    };

    var removeItem = function(itemId) {
        var newFileList = fileList.filter(function(f) {
            return f.id !== itemId;
        });

        if (newFileList.length !== (fileList.length - 1)) {
            throw new Error('removeItem, something went wrong');
        }

        fileList = newFileList;
        redrawQueue();
    };

    var isBrowserAndroid4Webkit = window.navigator.userAgent.indexOf(' AppleWebKit/534.30 ') !== -1;

    var invisibleFileInput = function(extraAttrs) {
        var t = null;

        if (isBrowserAndroid4Webkit) {
            // use the crazy one
            // we must support android-webkit-4, but it does this: if you call click() method
            // on a display-none file-input, it ignores it. so we have to hide it more creatively
            // and this approach seems to work
            t = '<div style="display: inline-block; width: 0; height: 0; position: relative"><input type="file" ' + extraAttrs + ' style="position: absolute; width: 0; height: 0"></div>';
        } else {
            t = '<input type="file" ' + extraAttrs + ' style="display:none">';
        }

        return t;
    };

    var buttonTextTemplateSel = '#fupload-button-' + settings.buttonStyle + '-template';

    var buttonText = mystache.get(buttonTextTemplateSel)({containerId: settings.containerId,
        buttonId: settings.buttonId,
        buttonText: settings.buttonText,
        cssClass: settings.cssClass,
        assetUrl: window.ASSET_URL,
        ctrlKey: isMac() ? 'Cmd' : 'Ctrl',
        multiple: settings.multiple,
    });

    $(buttonBox)
        .append(buttonText);

    var $fakeUpload = containerBox.find('#' + settings.buttonId);
    var realUploadId = settings.buttonId + '-real';

    var fileInput = invisibleFileInput('accept="image/jpeg,image/png" multiple id="' + realUploadId + '"');

    $fakeUpload.after(fileInput);

    var $realUpload = containerBox.find('#' + realUploadId);

    $(buttonBox).show();

    $fakeUpload.on('click', function() {
        $realUpload[0].click();
    });

    $(actionBox).on('click', '.cancel', function(e) {
        e.preventDefault();
        var itemId = $(this).closest('.fupload-queue-item').attr('id');
        removeItem(itemId);
    });

    $realUpload.on('change', function() {
        addItems(this.files);
        this.value = ''; // so that we can select the same file again later
    });

    this.isUploading = function() {
        return isQueueSending();
    };
};

export { Uploader, mystache };
