/* global $, getCSRFToken */
import { SOMETHING_WENT_WRONG_GOING_TO_RELOAD } from 'mk/autogenerated/translations/jquery.ajax.csrf.55c47fdc3ba68819188b597e4886fa5d'

// we setup jquery so that ajax correctly does django-csrf
export function initJqueryAjaxCsrf() {
    var initialCSRFToken = getCSRFToken();

    function safeMethod(method) {
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    }

    $.ajaxPrefilter(function(options, originalOptions, jqXHR) {
        var currentCSRFToken = getCSRFToken();

        if (currentCSRFToken !== initialCSRFToken) {
            // this is bad. it can happen when
            // the user re-logins in an another tab
            // and then comes back to this tab to do something
            jqXHR.abort();
            window.alert(SOMETHING_WENT_WRONG_GOING_TO_RELOAD);
            window.location.reload();
        }

        if ((!safeMethod(options.type)) && (!options.crossDomain)) {
            jqXHR.setRequestHeader('X-CSRFToken', currentCSRFToken);
        }
    });
}
