/*
    Simple wrapper that delegates calls to xhr but creates client apiAuth object for you, so each function needs
    just url and data.
 */

import * as xhr from './index';
import {makeClientApiAuth} from './apiAuth';

export const get = (url, data) => xhr.get(makeClientApiAuth(), url, data);
export const post = (url, data) => xhr.post(makeClientApiAuth(), url, data);
export const postUrlEncoded = (url, data) => xhr.postUrlEncoded(makeClientApiAuth(), url, data);
export const put = (url, data) => xhr.put(makeClientApiAuth(), url, data);
