/* global $, ASMIRA, setTimeout */

// this module is imported sometimes from code
// that is running on the server too
// so we make sure we only do things when we
// are in the browser
var inBrowser = typeof window !== 'undefined';

function handleAnim(msg) {
    var $msg = $(msg);

    if ($msg.next().length > 0) {
        var height = $msg.outerHeight(true);
        $msg.wrap('<div class="flash-message-wrapper"></div>');
        $msg.parent().height(height);

        setTimeout(function() {
            $msg.addClass('slide-from-right');
        }, 500);

        setTimeout(function() {
            $msg.unwrap();
        }, 1800);
    } else {
        setTimeout(function() {
            $msg.addClass('slide-from-right');
        }, 100);
    }

    if (!$msg.hasClass('permanent')) {
        setTimeout(function() {
            $msg.addClass('fade-out-1000');
        }, 4500);

        setTimeout(function() {
            $msg.remove();
        }, 5500);
    }

    $msg.click(function() {
        $msg.addClass('fade-out-1000');
        setTimeout(function() {
            $msg.remove();
        }, 1000);
    });
}

function display(msg, clazz) {
    var html = '<div class="flash-message ' + clazz + '">' + msg + '</div>';
    var $node = $(html);
    $('#session-messages').prepend($node);
    handleAnim($node[0]);
}

export function alert(msg) {
    if (!inBrowser) {
        return;
    }

    display(msg, '');
}

export function error(msg) {
    if (!inBrowser) {
        return;
    }

    display(msg, 'error permanent');
}

function genericMsg(messageType, message) {
    var fun = {
        'success': alert,
        'error': error,
    }[messageType];

    if (!fun) {
        throw new Error('msgs: invalid message-type:' + messageType);
    }

    fun(message);
}

export function init(messages) {
    if (!inBrowser) {
        return;
    }

    $('#session-messages div.flash-message').get().forEach(function(m) {
        handleAnim(m);
    });

    messages.forEach(function(d) {
        genericMsg(d.type, d.message);
    });
}
