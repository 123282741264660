import { jQuery as $ } from 'mk/jQuery';

function submitForm(form) {
    const formAction = $(form).attr('action');
    const formData = $(form).serialize();
    // Cancel if note is empty
    if ($(form).find('.note').val() === ''){
        return false;
    }

    // Disable submit button
    $(form).find('input.note-submit').attr('disabled', true);
    $(form).find('input.note-submit').val('Saving..');

    // Save
    $.ajax({
        type: 'POST',
        url: formAction,
        data: formData,
        dataType: 'json',
        success: function(returned_json) {
            if (returned_json.result === 0) {
                alert('Error has occured when adding new note.');
            } else {
                // Re-render box
                $(form).parents(".note-box-wrap").html(returned_json.html);
            }
        },
    });

    return false;
}

function deleteNote(elm) {
    // Confirm action
    if (!confirm('Are you sure ?')) {
        return false;
    }

    $(elm).hide();

    //console.log({'box_type':elm.getAttribute('box_type'), 'box_object_id':elm.getAttribute('box_object_id') });

    const formAction = $(elm).attr('href');

    // Delete note
    $.ajax({
        type: 'POST',
        url: formAction,
        data: {
            'box_type': elm.getAttribute('box_type'),
            'box_object_id': elm.getAttribute('box_object_id'),
        },
        dataType: 'json',
        success: function(returned_json) {
            if (returned_json.result === 0) {
                alert('Error has occured when adding new note.');
            } else {
                // Re-render box
                $(elm).parents(".note-box-wrap").html(returned_json.html);
            }
        },
    });

    return false;
}

function taskAction(elm) {
    // Confirm action
    if (!confirm('Are you sure ?')) {
        return false;
    }

    $(elm).hide();

    const formAction = $(elm).attr("href");

    // Delete note
    $.ajax({
        type: 'POST',
        url: formAction,
        dataType: 'json',
        data: {
            'box_type':elm.getAttribute('box_type'),
            'box_object_id':elm.getAttribute('box_object_id'),
        },
        success: function(returned_json){
            if (returned_json.result === 0) {
                alert('Error has occured when adding new note.');
            } else {
                // Re-render box
                $(elm).parents(".note-box-wrap").html(returned_json.html);
            }
        },
    });

    return false;
}

function taskDone(elm) {
    // Confirm action
    if (!confirm('Really done ?')) {
        return false;
    }

    $(elm).parent().hide();
    const url = elm.getAttribute('action_url');

    // Delete note
    $.ajax({
        type: "POST",
        url: url,
        data: {
            'box_type': elm.getAttribute('box_type'),
            'box_object_id': elm.getAttribute('box_object_id'),
        },
        dataType: 'json',
        success: function(returned_json) {
            if (returned_json.result === 0) {
                alert('Error has occured when adding new note.');
            } else {
                // Re-render box
                $(elm).parents(".note-box-wrap").html(returned_json.html);
            }
        },
    });

    return false;
}

export function install_notes_widget(elemId) {
    elemId = '#' + elemId;

    $(elemId + ' form').submit(function(event) {
        return submitForm(this);
    });

    $(elemId + ' a.delete-link').click(function(event) {
        return deleteNote(this);
    });

    $(elemId + ' a.task-action').click(function(event) {
        return taskAction(this);
    });

    $(elemId + ' input.task-done').change(function(event) {
        return taskDone(this);
    });

    $(elemId + ' .note').focus(function(event) {
        $(elemId + ' .cancel-link').show();
        $(this).height(120);
        return false;
    });

    $(elemId + ' .cancel-link').click(function(event) {
        $(elemId + ' .note').height(18);
        $(this).hide();
        return false;
    });

    $(elemId + ' .note_submit').attr('disabled', false);
}
