/* global WebSocket */

var socket = null;
var socketIsOpen = false;

var subscriptions = {};

var subsToSend = [];

export var trigger = function(channel) {
    var fun = subscriptions[channel];

    if (!fun) {
        console.error('wrong channel from jam:' + channel);
    } else {
        fun();
    }
};

var onMessage = function(event) {
    var message = event.data;
    var match = message.match(/^ping:(.+)$/);
    if (!match) {
        console.error('invalid message from jam:', message);
        return;
    }

    var channel = match[1];

    trigger(channel);
};

var sendSubs = function() {
    if (!(socket && socketIsOpen)) {
        return; // we cannot send yet, when we will be able, onOpen will call us
    }

    while (subsToSend.length > 0) {
        var channel = subsToSend.shift();
        socket.send('subscribe:' + channel);
    }
};

var onOpen = function() {
    socketIsOpen = true;
    sendSubs();
};

var websocketAvailable = function() {
    if (!('WebSocket' in window)) {
        return false;
    }

    // while normal android4-browser has no websocket support
    // some androids ("samsung galaxy s3 mini" for example)
    // has a not-working version. so we explicitly have to
    // disable it on android4-browser.
    if (window.navigator.userAgent.indexOf('AppleWebKit/534.30') !== -1) {
        return false;
    }

    return true;
};

export var init = function(config) {
    var domain = config.wsdomain;

    // disabling for Android 4.2
    if (websocketAvailable()) {
        socket = new WebSocket(domain);
        socket.onmessage = onMessage;
        socket.onopen = onOpen;
    }
};

export var subscribe = function(channel, fun) {
    subscriptions[channel] = fun;

    subsToSend.push(channel);
    sendSubs();
};
