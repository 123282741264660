import { jQuery as $ } from 'mk/jQuery';
import { loginUrl, signupUrl } from 'mk2/apps/users/urls';

declare const MK: any;

function authNeededHandler(e: MouseEvent | FocusEvent) {
    e.preventDefault();

    let nextUrl = $(e.currentTarget).data('authNext');
    if (nextUrl === undefined) {
        nextUrl = $(e.currentTarget).attr('href');
    }
    if (nextUrl === '/') {
        // do not redirect back, if clicked login/signup from homepage
        nextUrl = '';
    } else if (!nextUrl || nextUrl === '' || nextUrl === '#') {
        nextUrl = window.location.href.substring(window.location.origin.length);
    }

    const mode = $(e.currentTarget).data('authMode') || 'auto';
    const query = $(e.currentTarget).data('authQuery') || {};

    let gotoUrl;
    if (mode === 'login') {
        gotoUrl = loginUrl;
    } else if (mode === 'signup') {
        gotoUrl = signupUrl;
    } else {
        // nie je dane, ci ist na login alebo signup. Rozhodneme
        // podla toho ci uz ma cookie s username
        const cookieRegx = /_remember=[0-9a-z_.\-]+;/;
        const hasUsername = document.cookie.search(cookieRegx) >= 0;
        gotoUrl = hasUsername ? loginUrl : signupUrl;
    }

    if (nextUrl) {
        gotoUrl = gotoUrl + '?next=' + encodeURIComponent(nextUrl);
    }

    if (typeof query === 'string') {
        // Append custom query
        gotoUrl = gotoUrl + (gotoUrl.indexOf('?') === -1 ? '?' : '&') + query;
    } else {
        // Generate querystring from data
        const qs = [];
        for (const k in query) {
            if (query.hasOwnProperty(k)) {
                qs.push(encodeURIComponent(k) + '=' + encodeURIComponent(query[k]));
            }
        }
        if (qs.length) {
            gotoUrl = gotoUrl + (gotoUrl.indexOf('?') === -1 ? '?' : '&') + qs.join('&');
        }
    }

    window.location.href = gotoUrl;
}

export function init() {
    if (!MK.requestUser || MK.requestUser.id === 0) {
        $(document).on('click', '.auth-needed', authNeededHandler);
        $(document).on('focusin', 'textarea.auth-needed', authNeededHandler);
    }
}
