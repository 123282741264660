import * as _FieldUtils from 'mk/bzforms/fieldUtils';
import { jQuery as $ } from 'mk/jQuery';

export { PhotoUploadField } from 'mk/bzforms/PhotoUploadField';
export const FieldUtils = _FieldUtils;

export function focusFirst(formIdOrElem) {
    let formElem;
    if ($.type(formIdOrElem) === 'string') {
        formElem = $('#' + formIdOrElem);
    } else {
        formElem = formIdOrElem;
    }

    if (!focusError(formIdOrElem)) {
        formElem.find('tr input, .field-wrapper input:not([readonly]):not(:disabled), .field-wrapper textarea:not([readonly]:not(:disabled))').first().focus().select();
    }
}

function focusError(formIdOrElem) {
    let formElem;
    if ($.type(formIdOrElem) === 'string') {
        formElem = $('#' + formIdOrElem);
    } else {
        formElem = formIdOrElem;
    }

    const errorFields = formElem.find('tr.error, .field-wrapper.error');
    if (errorFields.length !== 0) {
        const e = errorFields.find('input');
        if (e && e.length) {
            e.first().focus().select();
        } else {
            errorFields.find('textarea').first().focus().select();
        }
        return true;
    }
    return false;
}
