import { BZFORM_ADD_PHOTOS, NOT_ALL_MESSAGE_PHOTOS_HAVE_BEEN_UPLOADED_SEND_ANYWAY } from 'mk/autogenerated/translations/PhotoUploadField.9e89df41aad8ef1db4825d53efbd61af'
import { jQuery as $ } from 'mk/jQuery';
import { photo_upload_url } from 'mk/urls/functions';
import { mystache, Uploader } from '../../apps/browserApp/fupload';

export const PhotoUploadField = {
    init(fieldElem, photoType, useNativeAndroidUpload,
         initialPhotoData = [], previewPhotoSize = 's150x150',
         multiple = true,
         buttonText = BZFORM_ADD_PHOTOS,
         busyText = NOT_ALL_MESSAGE_PHOTOS_HAVE_BEEN_UPLOADED_SEND_ANYWAY,
    ) {
        const uploadedPhotosContainer = fieldElem.find('.fupload-uploaded-photos');
        const tokensInput = fieldElem.find('input[type=hidden]');

        if (!useNativeAndroidUpload) {
            const fu = new Uploader({
                name: photoType,
                containerBox: fieldElem,
                buttonBox: fieldElem.find('.fupload-button'),
                actionBox: fieldElem.find('.fupload-action'),
                url: photo_upload_url(photoType),
                multiple,
                buttonText,
                buttonStyle: 'inline',
            });

            // callback when a photo upload finishes
            $(fu).bind('uploadend', (e, res) => {
                this.onPhotoUploaded(fieldElem, uploadedPhotosContainer, tokensInput, res, previewPhotoSize, multiple);
            });

            // prevent submitting the form while photos are uploading
            const formElem = fieldElem.closest('.g-form');
            formElem.submit((ev) => {
                if (fu.isUploading() && !confirm(busyText)) {
                    ev.stopImmediatePropagation();
                    return false;
                }
            });
        } else {
            // Callback from android app when a photo upload has finished.
            // Currently implemented as global function on Window, so it
            // does not support multiple PhotoUploadFields on the same page
            (window as any).mkn_uploadend = (res) => {
                this.onPhotoUploaded(fieldElem, uploadedPhotosContainer, tokensInput, res, previewPhotoSize, multiple);
            };
        }

        // handler for clicks that remove uploaded photo
        uploadedPhotosContainer.on('click', 'a.remove', (ev) => {
            ev.preventDefault();

            const photoElem = $(ev.target).closest('.uploaded-photo');
            const code = photoElem.data('code');

            // remove token of deleted photo
            let tokens = tokensInput.val();
            tokens = tokens.split(',').filter((s) => s !== code).join(',');
            tokensInput.val(tokens);
            tokensInput.trigger('change');

            photoElem.fadeOut(250, () => {
                photoElem.remove();
            });
        });

        // render preview of initial photos
        for (const res of initialPhotoData) {
            this.showPhotoPreview(fieldElem, uploadedPhotosContainer, res, previewPhotoSize, multiple, false);
        }
    },

    /**
     * display preview of uploaded photo
     */
    showPhotoPreview(fieldElem, uploadedPhotosContainer, res, previewPhotoSize, multiple, fade = true) {
        const templateElem = fieldElem.find('#fupload-photo-template');
        const item = mystache.get(templateElem)({
            url: res.photo_versions[previewPhotoSize].url,
            code: res.photo_code,
            height: res.photo_versions[previewPhotoSize].height,
            width: res.photo_versions[previewPhotoSize].width,
        });

        if (!multiple) {
            uploadedPhotosContainer.html('');
        }

        fade
            ? $(item).hide().appendTo(uploadedPhotosContainer).fadeIn(250)
            : $(item).appendTo(uploadedPhotosContainer);
    },

    /**
     * handler called when a photo upload has finished
     */
    onPhotoUploaded(fieldElem, uploadedPhotosContainer, tokensInput, res, previewPhotoSize, multiple) {
        // append token of uploaded photo
        let tokens;
        if (multiple) {
            tokens = tokensInput.val();
            tokens += (tokens ? ',' : '') + res.photo_code;
        } else {
            tokens = res.photo_code;
        }
        tokensInput.val(tokens);
        tokensInput.trigger('change');

        // show preview of uploaded photo
        this.showPhotoPreview(fieldElem, uploadedPhotosContainer, res, previewPhotoSize, multiple);
    },
};
