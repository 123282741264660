export function initJqueryShowYellowFade($) {
    $.fn.showYellowFade = function(elemBg) {
        if (!this.length) return;

        if (elemBg) {
            var oldc = this.css('background-color');
            this.css({backgroundColor: elemBg});
        }

        this.effect('highlight', {color: '#fff5d3' /* $hover-yellow */, easing: 'easeInQuint'}, 5000 );

        if (elemBg && oldc) {
            this.css({backgroundColor: oldc});
        }
    };
}
