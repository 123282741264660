// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const CANCEL = "Zru\u0161";
export const DELETE_COMMENT = "Vyma\u017e";
export const FUPLOAD_DISABLE_PREPROCESSING = "Nahraj fotku v max. kvalit\u011b (trv\u00e1 to d\u00e9le)";
export const HOLD_DOWN_KEY = "P\u0159i v\u00fdb\u011bru fotek podr\u017e kl\u00e1vesu";
export const NOT_LOGGED = "nep\u0159ihl\u00e1\u0161en\u00fd";
export const REPLY_LINK = "Odpov\u011bz";
export const SAVE_DESCRIPTION = "Ulo\u017e popis";
export const SENDING_PHOTOGRAPH = "Pos\u00edl\u00e1m fotografii...";
export const SENDING_PHOTOGRAPHS = "Pos\u00edl\u00e1m fotografie...";
export const TIP = "Tip";
export const TO_SELECT_MULTIPLE_IMAGES = "abys vybrala v\u00edce fotek najednou";
