import { initialize } from 'mk2/apps/blogs/initialize';
import { Footer } from 'mk2/components/Footer';
import React from 'react';
import ReactDOM from 'react-dom';

let pageFooterElement: HTMLElement;

export function mountPageFooter(element: HTMLElement) {
    if (pageFooterElement) {
        // Unmount current react app
        ReactDOM.unmountComponentAtNode(pageFooterElement);
        pageFooterElement = null;
        return;
    }

    if (element) {
        pageFooterElement = element;
        initialize(element, (props) => (
            <Footer/>
        ));
    }
}
